import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import ArticleThumbnail from './ArticleThumbnail'
import EventSection from './EventSection'

const ArticleSection = () => {
	const data = useStaticQuery(graphql`
		query ArticlesQuery {
			allWordpressWpArticle(sort: {fields: date, order: DESC}) {
				edges {
					node {
						id
						title
						slug
						area {
							name
						}
						featured_media {
							localFile {
								id
								childImageSharp {
									fluid(maxWidth: 400, quality: 90) {
										...GatsbyImageSharpFluid_noBase64
									}
								}
							}
						}
					}
				}
			}
		}
	`)

	return (
		<div className="bg-green-bg py-12 px-2 md:px-6 max-w-screen-2xl lg:px-10 xl:px-20 mx-auto lg:mt-4">
			<div className="w-full mx-auto">
				<h2 className="text-3xl text-center leading-tight font-bold md:text-left lg:text-4xl">
					Aktuellt i Halland
				</h2>
				<div className="flex flex-wrap pt-6 max-w-screen-2xl mx-auto md:justify-between">
					<div className="w-full md:w-49p md:flex md:flex-wrap lg:hidden">
						{data.allWordpressWpArticle.edges
							.slice(0, 2)
							.map(item => (
								<div
									key={item.node.id}
									className="w-full px-2 mb-3 md:h-auto md:p-0 md:mb-6 lg:w-48-5p rounded-lg"
								>
									<ArticleThumbnail
										categories={item.node.area}
										title={item.node.title}
										url={`artiklar/${item.node.slug}`}
										image={
											item.node.featured_media
												? item.node.featured_media
														.localFile
														.childImageSharp
												: null
										}
									/>
								</div>
							))}
					</div>
					<div className="flex-wrap w-66p justify-between hidden lg:flex">
						{data.allWordpressWpArticle.edges
							.slice(0, 4)
							.map(item => (
								<div
									key={item.node.id}
									className="w-full px-2 mb-3 md:h-auto md:p-0 md:mb-6 lg:w-48-5p rounded-lg"
								>
									<ArticleThumbnail
										categories={item.node.area}
										title={item.node.title}
										url={`artiklar/${item.node.slug}`}
										image={
											item.node.featured_media ? item.node.featured_media.localFile.childImageSharp	: null
										}
									/>
								</div>
							))}
					</div>
					<div className="hidden md:block w-49p md:mb-6 lg:w-32p">
						<EventSection />
					</div>
					<div className='lg:hidden w-full flex flex-wrap justify-between'>
						{data.allWordpressWpArticle.edges
							.slice(2, data.length)
							.map(item => (
								<div
									key={item.node.id}
									className="w-full px-2 mb-3 md:w-49p md:h-auto md:p-0 md:mb-6 lg:w-32p"
								>
									<ArticleThumbnail
										categories={item.node.area}
										title={item.node.title}
										url={`artiklar/${item.node.slug}`}
										image={
											item.node.featured_media ? item.node.featured_media.localFile.childImageSharp	: null
										}
									/>
								</div>
							))}
					</div>
					<div className='hidden lg:contents'>
						{data.allWordpressWpArticle.edges
							.slice(4, data.length)
							.map(item => (
								<div
									key={item.node.id}
									className="w-full px-2 mb-3 md:w-49p md:h-auto md:p-0 md:mb-6 lg:w-32p"
								>
									<ArticleThumbnail
										categories={item.node.area}
										title={item.node.title}
										url={`artiklar/${item.node.slug}`}
										image={
											item.node.featured_media ? item.node.featured_media.localFile.childImageSharp	: null
										}
									/>
								</div>
							))}
					</div>
				</div>
			</div>
		</div>
	)
}

ArticleSection.propTypes = {
	title: PropTypes.string,
}

ArticleSection.defaultProps = {
	title: `Välkommen till BusinessHalland`,
}

export default ArticleSection
