import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

const ArticleThumbnail = ({ title, categories, image, url }) => {
	return (
		<Link className="w-full h-full rounded-lg" to={url}>
			<div className="bg-white shadow-sm flex md:flex-col-reverse md:h-full md:flex md:justify-between rounded-lg">
				<div className={
					"p-3 sm:p-6 border-r-4 border-green-accent w-65p md:border-r-0 md:w-full " +
					"md:flex-grow-1 md:justify-between md:flex md:flex-col"}>
					<span>
						{categories && (
							<span
								className="block font-normal text-xs text-gray-400 mb-1"
								dangerouslySetInnerHTML={{
									__html: categories
										.map(category => category.name)
										.join(' • '),
								}}
							></span>
						)}
						<h3 className="text-lg leading-tight mb-4">
							{title}
						</h3>
					</span>
					<Link className="text-sm underline arrow-icon relative block" to={url}>
						Läs mer
					</Link>
				</div>
				{image && (
					<Img
						fluid={image.fluid}
						objectFit="cover"
						objectPosition="50% 50%"
						className="w-35p md:w-full md:h-64 md:border-b-4 border-green-accent rounded-t-lg"
					/>
				)}
			</div>
		</Link>
	)
}

ArticleThumbnail.propTypes = {
	title: PropTypes.string.isRequired,
	categories: PropTypes.array,
	url: PropTypes.string.isRequired,
	image: PropTypes.object.isRequired,
}

export default ArticleThumbnail
