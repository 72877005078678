import React, { useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import ReactHtmlParser from 'react-html-parser'
import styled from 'styled-components'
import tw from 'tailwind.macro'

const LandingFirstArticle = ({ title, content, button, greentext }) => {
	const [widgetOpen, setWidgetOpen] = useState(false);

	useEffect(() => {
		const iframe = document.getElementById('business-halland-widget')

		if (!iframe) {
			console.error('Business Halland is not initialized.')
			return
		}

		if (widgetOpen){
			iframe.contentWindow.postMessage({ type: 'WIDGET_OPEN' }, '*')
		} else {
			iframe.contentWindow.postMessage({ type: 'WIDGET_CLOSE' }, '*')
		}
		
	}, [widgetOpen])

	return (
    <div className='max-w-screen-2xl md:px-6 lg:px-10 xl:px-20 mx-auto px-4 relative -mt-56 md:-mt-48 lg:-mt-40 xl:-mt-32'>
      <div className="bg-white rounded-lg px-4 py-6 md:py-16 md:px-8 md:text-center">
        <div className='text-green-accent font-bold text-sm md:text-base lg:text-xl'>{greentext}</div>
        <div
					className='font-bold text-2xl leading-6 py-2 md:text-3xl lg:text-4xl xl:text-45 xl:pt-4 xl:pb-8'>
					{title}
				</div>
				<div className='md:pt-6 md:px-6 lg:px-10 xl:px-16 mx-auto xl:w-90p'>
        	<StyledArticle>{ReactHtmlParser(content)}</StyledArticle>
				</div>
				<button
					className={
						'hidden md:inline bg-green-accent hover:bg-green-accent-dark text-white rounded-lg w-56 px-6 ' +
						'py-3 outline-none focus:outline-none text-center ease-in duration-300'
					}
					onClick={() => setWidgetOpen(!widgetOpen)}
				>
					{button}
				</button>
      </div>
    </div>
	)
}

const StyledArticle = styled.article`
	> p {
		${tw`text-lg mb-4 lg:mb-8 lg:text-xl`};
		a {
			${tw`text-green-accent font-bold underline`};
		}
	}
`

LandingFirstArticle.propTypes = {
	title: PropTypes.string,
	greentext: PropTypes.string,
}

LandingFirstArticle.defaultProps = {
	title: `Här kan du utforska det halländska erbjudandet`,
  greentext: `Ta nästa steg i ditt företagande`,
	button: `Utforska erbjudanden`,
}

export default LandingFirstArticle
