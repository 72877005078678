import React, { useRef } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Layout from '../components/Layout'
import LandingHero from '../components/LandingHero'
import EventSection from '../components/EventSection'
import ArticleSection from '../components/ArticleSection'
import SEO from '../components/Seo'
import LandingFirstArticle from '../components/LandingFirstArticle'

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);

const IndexPage = () => {
	const data = useStaticQuery(graphql`
		query HomeQuery {
			wordpressPage(slug: { eq: "valkommen-till-businesshalland" }) {
				id
				title
				content
				featured_media {
					localFile {
						childImageSharp {
							fluid(quality: 100) {
								...GatsbyImageSharpFluid_withWebp
							}
						}
					}
				}
				acf {
					forsta_avsnittet {
						content
						texta
						titel
					}
				}
			}
		}
	`)

	const myRef = useRef(null);
  const executeScroll = () => scrollToRef(myRef);

	return (
		<Layout>
			<SEO title="Hem" metaTitle='Business Halland' description='Business Halland gör det enklare för dig som företagare att hitta och ta del av de erbjudan och evenemang i Halland.'/>
			<LandingHero
				executeScroll={executeScroll}
				title={data.wordpressPage.title}
				quote={data.wordpressPage.content.replace(
					/<[^>]*>?/gm,
					''
				)}
				image={
					data.wordpressPage.featured_media.localFile.childImageSharp
						.fluid
						? data.wordpressPage.featured_media.localFile
								.childImageSharp.fluid
						: null
				}
			/>
			<div ref={myRef}></div>
			<LandingFirstArticle
				title={data.wordpressPage.acf.forsta_avsnittet.titel}
				content={data.wordpressPage.acf.forsta_avsnittet.content}
				greentext={data.wordpressPage.acf.forsta_avsnittet.texta}
			/>
			<ArticleSection />
			<div className='md:hidden'>
				<EventSection />
			</div>
		</Layout>
	)
}

export default IndexPage
