import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import parse from 'date-fns/parse'
import { MdOutlineCalendarToday } from 'react-icons/md'
import LandingEventListItem from './LandingEventListItem'

const EventSection = () => {
	const data = useStaticQuery(graphql`
		query EventsQuery {
			allWordpressWpEvent {
				edges {
					node {
						id
						title
						slug
						content
						municipality {
							name
						}
						acf {
							end_date
							start_date
							link
						}
					}
				}
			}
		}
	`)
	// Remove passed events and get the most upcoming events

	const upcomingEvents = data.allWordpressWpEvent.edges
		.filter(event => {
			let eventDate = parse(
				event.node.acf.end_date,
				'yyyy-M-d k:m:s',
				new Date()
			)
			return eventDate > new Date()
		})
		.sort((a, b) => {
			const aDate = parse(
				a.node.acf.start_date,
				'yyyy-MM-dd kk:m:s',
				new Date()
			)
			const bDate = parse(
				b.node.acf.start_date,
				'yyyy-MM-dd kk:m:s',
				new Date()
			)
			return aDate - bDate
		})

	return (
		<div className="mx-auto h-full">
			<div className="w-full mx-auto px-3 bg-white pb-10 shadow-sm h-full rounded-lg">
				<div className="flex items-center px-2 py-8">
					<MdOutlineCalendarToday className="w-6 h-5" />
					<div className="px-3 text-lg">Evenemangskaleder</div>
				</div>
				{upcomingEvents.length !== 0 ? (
					<ul className="list-reset px-2">
						{upcomingEvents.slice(0, 6).map(event => {
							const startDate = parse(
								event.node.acf.start_date,
								'yyyy-MM-dd kk:m:s',
								new Date()
							)

							return (
								<LandingEventListItem
									key={event.node.id}
									title={event.node.title}
									url={event.node.acf.link}
									startDate={startDate}
									municipalities={
										event.node.municipality
											? event.node.municipality
											: []
									}
								/>
							)
						})}
					</ul>
				) : (
					<div className="text-center py-10">
						Det finns inga kommande evenemang
					</div>
				)}
				{upcomingEvents.length !== 0 && (
					<div className="w-full flex justify-center items-center pt-6">
						<Link
							to="/evenemang"
							className="underline text-lg font-semibold"
						>
							Se alla evenemang
						</Link>
					</div>
				)}
			</div>
			<div className="h-24 bg-green-bg md:hidden"></div>
		</div>
	)
}

export default EventSection
