import PropTypes from 'prop-types'
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import React from 'react'
import { format } from 'date-fns'
import { sv } from 'date-fns/locale'
import { MdArrowForwardIos } from "react-icons/md";

const LandingEventListItem = ({ title, url, startDate, municipalities }) => (
	<OutboundLink
		href={url}
		target="_blank"
	>
		<li className={
      "p-4 mb-2 bg-green-bg rounded-md " +
      "flex items-center justify-between"}>
			<div className='pr-2'>
				<span className="text-sm block font-bold text-green-accent mb-1">
					{format(new Date(startDate), 'd MMMM', { locale: sv })} •{' '}
					{municipalities
						.map(municipality => municipality.name)
						.join(' - ')}
				</span>
				<h3
					className="text-lg max-w-xl leading-tight mb-2"
					dangerouslySetInnerHTML={{ __html: title }}
				></h3>
			</div>
      <div className='w-12p flex justify-end items-center'>
        <MdArrowForwardIos className='w-5 h-5'/>
      </div>
		</li>
	</OutboundLink>
)

LandingEventListItem.propTypes = {
	title: PropTypes.string.isRequired,
	url: PropTypes.string.isRequired,
}

export default LandingEventListItem
