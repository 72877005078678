import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import Img from 'gatsby-image'
import styled from 'styled-components'
import tw from 'tailwind.macro'

const LandingHero = ({ title, image, quote, executeScroll }) => {
	const [widgetReady, setWidgetReady] = useState(false)

	useEffect(() => {
		window.addEventListener(
			'message',
			e => {
				if (e.data.type === 'WIDGET_READY') setWidgetReady(true)
			},
			'*'
		)
	}, [widgetReady])

	return (
		<div className="relative flex justify-center">
			{image && (
				<StyledImg
					className="w-full bottom-0 top-0 left-0 z-0 h-screenWithoutNav"
					objectFit="cover"
					objectPosition="50% 50%"
					fluid={image}
				/>
			)}
			<div
				className={
					'container absolute z-20 top-0 h-screenWithoutNav pt-16 xl:pt-32 ' +
					'px-2 max-w-screen-2xl md:px-6 lg:px-10 xl:px-20 lg:flex md:pt-24 lg:justify-between'
				}
			>
				<div className="w-full px-3 md:px-0 md:w-2/3 lg:w-3/4 lg:pr-10 xl:w-56p">
					<p className="font-bold text-lg text-white pb-4 lg:text-xl md:pb-0">
						Business Halland
					</p>
					<h1
						className={
							'text-3xl leading-none text-white font-bold mb-6 md:text-5xl ' +
							'xl:text-6xl md:pb-4 lg:pb-8 xl:pb-10'
						}
					>
						{title}
					</h1>
				</div>
				{quote && (
					<div className="hidden md:inline-block text-white italic md:font-semibold mt-20 lg:w-1/4 text-right">
						{quote}
					</div>
				)}
			</div>
		</div>
	)
}

const StyledImg = styled(Img)`
	&:before {
		content: '';
		${tw`absolute block w-full h-full z-10`}
		background: linear-gradient(
			to top,
			rgba(0, 0, 0, 0.5),
			rgba(0, 0, 0, 0)
		);
	}
`

LandingHero.propTypes = {
	title: PropTypes.string,
}

LandingHero.defaultProps = {
	title: `Information för dig som vill göra business i Halland`,
}

export default LandingHero
